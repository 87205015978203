import {digg} from "diggerize"
import modelRecipes from "./model-recipes.json"
import ModelRecipesLoader from "@kaspernj/api-maker/build/model-recipes-loader"
/* rails-erb-loader-dependencies api_maker/resources/ models/ */


const loader = new ModelRecipesLoader({recipes: modelRecipes})
const result = loader.load()



  

  const AccountBanModelClass = digg(result, "AccountBan")

  class AccountBan extends AccountBanModelClass {}

  

  const AccountInvitationModelClass = digg(result, "AccountInvitation")

  class AccountInvitation extends AccountInvitationModelClass {}

  

  const AccountModelClass = digg(result, "Account")

  class Account extends AccountModelClass {}

  

  const AccountUserModelClass = digg(result, "AccountUser")

  class AccountUser extends AccountUserModelClass {}

  

  const AccountUserWorkAreaModelClass = digg(result, "AccountUserWorkArea")

  class AccountUserWorkArea extends AccountUserWorkAreaModelClass {}

  

  const AccountWorkAreaModelClass = digg(result, "AccountWorkArea")

  class AccountWorkArea extends AccountWorkAreaModelClass {}

  

  const AddressModelClass = digg(result, "Address")

  class Address extends AddressModelClass {}

  

  const BoughtProjectPartAccessModelClass = digg(result, "BoughtProjectPartAccess")

  class BoughtProjectPartAccess extends BoughtProjectPartAccessModelClass {}

  

  const CalendarEventParticipantModelClass = digg(result, "CalendarEventParticipant")

  class CalendarEventParticipant extends CalendarEventParticipantModelClass {}

  

  const CalendarEventModelClass = digg(result, "CalendarEvent")

  class CalendarEvent extends CalendarEventModelClass {}

  

  const ChargeModelClass = digg(result, "Charge")

  class Charge extends ChargeModelClass {}

  

  const CurrencyModelClass = digg(result, "Currency")

  class Currency extends CurrencyModelClass {}

  

  const DroppedReasonModelClass = digg(result, "DroppedReason")

  class DroppedReason extends DroppedReasonModelClass {}

  

  const FileAttachmentModelClass = digg(result, "FileAttachment")

  class FileAttachment extends FileAttachmentModelClass {}

  

  const LocaleModelClass = digg(result, "Locale")

  class Locale extends LocaleModelClass {}

  

  const MessageModelClass = digg(result, "Message")

  class Message extends MessageModelClass {}

  

  const MessageThreadModelClass = digg(result, "MessageThread")

  class MessageThread extends MessageThreadModelClass {}

  

  const ModelDataVersionModelClass = digg(result, "ModelDataVersion")

  class ModelDataVersion extends ModelDataVersionModelClass {}

  

  const NotificationModelClass = digg(result, "Notification")

  class Notification extends NotificationModelClass {}

  

  const OfferModelClass = digg(result, "Offer")

  class Offer extends OfferModelClass {}

  

  const OfferVersionModelClass = digg(result, "OfferVersion")

  class OfferVersion extends OfferVersionModelClass {}

  

  const PartnerNotificationSettingModelClass = digg(result, "PartnerNotificationSetting")

  class PartnerNotificationSetting extends PartnerNotificationSettingModelClass {}

  

  const PaymentTermModelClass = digg(result, "PaymentTerm")

  class PaymentTerm extends PaymentTermModelClass {}

  

  const PlanModelClass = digg(result, "Plan")

  class Plan extends PlanModelClass {}

  

  const PreparedSubscriptionModelClass = digg(result, "PreparedSubscription")

  class PreparedSubscription extends PreparedSubscriptionModelClass {}

  

  const ProjectPartModelClass = digg(result, "ProjectPart")

  class ProjectPart extends ProjectPartModelClass {}

  

  const ProjectModelClass = digg(result, "Project")

  class Project extends ProjectModelClass {}

  

  const RegionModelClass = digg(result, "Region")

  class Region extends RegionModelClass {}

  

  const RoleModelClass = digg(result, "Role")

  class Role extends RoleModelClass {}

  

  const SmsMessageModelClass = digg(result, "SmsMessage")

  class SmsMessage extends SmsMessageModelClass {}

  

  const StripeCardModelClass = digg(result, "StripeCard")

  class StripeCard extends StripeCardModelClass {}

  

  const StripeChargeModelClass = digg(result, "StripeCharge")

  class StripeCharge extends StripeChargeModelClass {}

  

  const StripeCouponModelClass = digg(result, "StripeCoupon")

  class StripeCoupon extends StripeCouponModelClass {}

  

  const StripeCustomerModelClass = digg(result, "StripeCustomer")

  class StripeCustomer extends StripeCustomerModelClass {}

  

  const StripeDiscountModelClass = digg(result, "StripeDiscount")

  class StripeDiscount extends StripeDiscountModelClass {}

  

  const StripeInvoiceItemModelClass = digg(result, "StripeInvoiceItem")

  class StripeInvoiceItem extends StripeInvoiceItemModelClass {}

  

  const StripeInvoiceModelClass = digg(result, "StripeInvoice")

  class StripeInvoice extends StripeInvoiceModelClass {}

  

  const StripePaymentIntentModelClass = digg(result, "StripePaymentIntent")

  class StripePaymentIntent extends StripePaymentIntentModelClass {}

  

  const StripePaymentMethodModelClass = digg(result, "StripePaymentMethod")

  class StripePaymentMethod extends StripePaymentMethodModelClass {}

  

  const StripePlanModelClass = digg(result, "StripePlan")

  class StripePlan extends StripePlanModelClass {}

  

  const StripeProductModelClass = digg(result, "StripeProduct")

  class StripeProduct extends StripeProductModelClass {}

  

  const StripeSubscriptionItemModelClass = digg(result, "StripeSubscriptionItem")

  class StripeSubscriptionItem extends StripeSubscriptionItemModelClass {}

  

  const StripeSubscriptionModelClass = digg(result, "StripeSubscription")

  class StripeSubscription extends StripeSubscriptionModelClass {}

  

  const SubscriptionPeriodModelClass = digg(result, "SubscriptionPeriod")

  class SubscriptionPeriod extends SubscriptionPeriodModelClass {}

  

  const SubscriptionModelClass = digg(result, "Subscription")

  class Subscription extends SubscriptionModelClass {}

  

  const SubscriptionWorkAreaModelClass = digg(result, "SubscriptionWorkArea")

  class SubscriptionWorkArea extends SubscriptionWorkAreaModelClass {}

  

  const TableSearchModelClass = digg(result, "TableSearch")

  class TableSearch extends TableSearchModelClass {}

  

  const TableSettingColumnModelClass = digg(result, "TableSettingColumn")

  class TableSettingColumn extends TableSettingColumnModelClass {}

  

  const TableSettingModelClass = digg(result, "TableSetting")

  class TableSetting extends TableSettingModelClass {}

  

  const UserModelClass = digg(result, "User")

  class User extends UserModelClass {}

  

  const UserRoleModelClass = digg(result, "UserRole")

  class UserRole extends UserRoleModelClass {}

  

  const VatNumberModelClass = digg(result, "VatNumber")

  class VatNumber extends VatNumberModelClass {}

  

  const WorkAreaModelClass = digg(result, "WorkArea")

  class WorkArea extends WorkAreaModelClass {}

  

  const WorkplaceLinkModelClass = digg(result, "WorkplaceLink")

  class WorkplaceLink extends WorkplaceLinkModelClass {}

  

  const WorkplaceModelClass = digg(result, "Workplace")

  class Workplace extends WorkplaceModelClass {}


export {AccountBan, AccountInvitation, Account, AccountUser, AccountUserWorkArea, AccountWorkArea, Address, BoughtProjectPartAccess, CalendarEventParticipant, CalendarEvent, Charge, Currency, DroppedReason, FileAttachment, Locale, Message, MessageThread, ModelDataVersion, Notification, Offer, OfferVersion, PartnerNotificationSetting, PaymentTerm, Plan, PreparedSubscription, ProjectPart, Project, Region, Role, SmsMessage, StripeCard, StripeCharge, StripeCoupon, StripeCustomer, StripeDiscount, StripeInvoiceItem, StripeInvoice, StripePaymentIntent, StripePaymentMethod, StripePlan, StripeProduct, StripeSubscriptionItem, StripeSubscription, SubscriptionPeriod, Subscription, SubscriptionWorkArea, TableSearch, TableSettingColumn, TableSetting, User, UserRole, VatNumber, WorkArea, WorkplaceLink, Workplace}
